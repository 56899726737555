<template>
  <app-layout>
    <div class="px-4 px-sm-5 container">
      <async-object
        should-fetch
        :fetch-method="() => $store.dispatch('invoices/fetchById', {
          id: $route.params.id,
          storeId: currentStoreId
        })"
      >
        <div class="heading d-flex justify-content-between mb-5">
          <h2>Facture <span v-if="invoice && invoice.reference">{{ invoice.reference }}</span></h2>
        </div>

        <div class="row">
          <div class="col-12">
            <div class="mb-4">
              <router-link class="btn btn-link btn-sm" :to="{ name: 'invoices.index' }" active-class="active">
                <fa-icon :icon="['fas', 'chevron-left']" size="sm"></fa-icon>&nbsp;Retour
              </router-link>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-12">
            <div class="mb-4">
              <button @click.prevent="download" class="btn btn-primary btn-sm" :disabled="isDownloading" >
                <fa-icon :icon="['fas', 'cloud-download-alt']" size="sm"></fa-icon> Télécharger
              </button>
            </div>
          </div>
        </div>

        <template v-if="invoice">
          <div class="row">
            <div class="col-12 col-md-6">
              <div class="card mb-4">
                <div class="card-body">
                  <h4 class="header-title mb-4">Sommaire</h4>
                  <dl class="row">
                    <dt class="col-4">Date</dt>
                    <dd class="col-8">{{ $longDateTime(invoice.date) }}</dd>
                  </dl>
                  <dl class="row">
                    <dt class="col-4">Commande</dt>
                    <dd class="col-8">{{ invoice.order.reference }}</dd>
                  </dl>
                </div>
              </div>
            </div>

            <div class="col-12 col-md-6">
              <div class="card mb-4">
                <div class="card-body">
                  <h4 class="header-title mb-4">Adresse de facturation</h4>
                  <p v-if="invoice.billingAddress" class="address">
                    {{ invoice.billingAddress.name }}
                    <template v-if="invoice.billingAddress.company">
                      <br> {{ invoice.billingAddress.company }}
                    </template>
                    <br>{{ invoice.billingAddress.address }}
                    <template v-if="invoice.billingAddress.address2">
                      <br> {{ invoice.billingAddress.address2 }}
                    </template>
                    <br>{{ invoice.billingAddress.city }}, {{ invoice.billingAddress.province }}, {{ invoice.billingAddress.postalCode }}
                    <br>Canada
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-12">
              <div class="card mb-4">
                <div class="card-body p-0">
                  <h4 class="header-title with-padding">Articles facturés</h4>

                  <div class="table-responsive">
                    <table class="table nowrap-table">
                      <thead>
                      <tr>
                        <th>Article</th>
                        <th class="col-100 text-center">Quantité</th>
                        <th class="col-100 text-center">Prix</th>
                        <th class="col-100 text-center">Sous-total</th>
                        <th class="col-100 text-center" v-for="taxe in listTaxes" :key="taxe.key">{{ taxe.label }}</th>
                        <th class="col-100 text-center">Total</th>
                      </tr>
                      </thead>
                      <tbody>
                      <tr v-for="product in invoice.invoiceProducts" :key="product.id">
                        <td>
                          <router-link target="_blank" :to="`/products?variantId=${product.variantId}`">
                            {{ product.productName }} <span v-if="product.variantName">- {{ product.variantName }}</span>
                            <template v-if="product.variant">
                              <br><small>SKU: {{ product.variant.sku }}</small>
                            </template>
                          </router-link>
                        </td>
                        <td class="text-center">{{ product.quantity }}</td>
                        <td class="text-center">{{ $money(product.unitPrice) }}</td>
                        <td class="text-center">{{ $money(product.subtotal) }}</td>
                        <td class="text-center" v-for="taxe in listTaxes" :key="taxe.key">{{ displayProductTaxes (taxe.key, product.taxes) }}</td>
                        <td class="text-center">{{ $money(product.total) }}</td>
                      </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-12 col-md-6 offset-md-6">
              <div class="card mb-4">
                <div class="card-body">
                  <dl class="row">
                    <dt class="col-4">Sous-total</dt>
                    <dd class="col-8 text-right">{{ $money(invoice.subtotal) }}</dd>
                  </dl>
                  <dl v-for="(tax, index) in taxes" :key="`tax-${index}`"  class="row">
                    <dt class="col-4">{{ tax.label }}</dt>
                    <dd class="col-8 text-right">{{ $money(tax.value) }}</dd>
                  </dl>
                  <dl class="row">
                    <dt class="col-4">Frais d'expédition</dt>
                    <dd class="col-8 text-right">{{ $money(invoice.shipping) }}</dd>
                  </dl>
                  <dl class="row">
                    <dt class="col-4"><strong>Total</strong></dt>
                    <dd class="col-8 text-right">{{ $money(invoice.total) }}</dd>
                  </dl>
                </div>
              </div>
            </div>
          </div>
        </template>
      </async-object>
    </div>
  </app-layout>
</template>

<script>
import formatsDate from '@/mixins/formatsDate'
import download from '@/mixins/download'
import formatsCurrency from '@/mixins/formatsCurrency'
import userData from '@/mixins/user-data'

export default {
  mixins: [formatsDate, userData, formatsCurrency, download],
  data () {
    return {
      isDownloading: false
    }
  },
  computed: {
    taxes () {
      if (!this.invoice || !this.invoice.taxes || !Object.values(this.invoice.taxes).length) {
        return null
      }

      return Object.entries(this.invoice.taxes).map((entry) => {
        const [key, value] = entry

        if (key === 'gst_hst') {
          return {
            label: 'TPS/TVH',
            value
          }
        }

        if (key === 'qst') {
          return {
            label: 'TVQ',
            value
          }
        }

        return {
          label: key,
          value
        }
      })
    },
    listTaxes () {
      const taxes = []

      this.invoice.invoiceProducts.forEach((product) => {
        if (product.taxes && Object.values(product.taxes).length > 0) {
          Object.entries(product.taxes).forEach((entry) => {
            const [key] = entry

            const isAdded = taxes.find(x => x.key === key)

            if (!isAdded) {
              let label = ''

              switch (key) {
                case 'gst_hst':
                  label = 'TPS/TVH'
                  break
                case 'qst':
                  label = 'TVQ'
                  break

                default:
                  label = ''
                  break
              }

              taxes.push({ key, label })
            }
          })
        }
      })

      return taxes
    },
    invoice () {
      return this.$store.getters['invoices/getDetailsById'](this.$route.params.id)
    }
  },
  methods: {
    async download () {
      this.isDownloading = true
      await this.downloadFile(`/v1/stores/${this.currentStoreId}/invoices/${this.$route.params.id}/pdf`)
      this.isDownloading = false
    },
    displayProductTaxes (taxeKey, productTaxes) {
      let amount = null

      if (productTaxes && Object.values(productTaxes).length > 0) {
        amount = productTaxes[taxeKey] ? productTaxes[taxeKey] : null
      }

      return amount ? this.$money(amount) : ''
    }
  }
}
</script>

<style lang="scss" scoped>
.address {
  line-height: 24px;
}
</style>
